// Here you can add other styles
@font-face {
  font-family: 'NotoSansRegular';
  src: url(../assets/fonts/NotoSans-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'NotoSansBold';
  src: url(../assets/fonts/NotoSans-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'TypoRound';
  src: url(../assets/fonts/Typo_Round_Regular_Demo.otf);
  font-display: swap;
}

body {
  font-family: 'Manrope', 'sans-serif';
  background-color: #f7f7f7;

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #231f20;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    transition: 0.2s ease-in-out;
    visibility: hidden;
    background-color: #534c4d;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
      // opacity: 1;
    }
  }
}

label {
  margin-bottom: 0 !important;
}

.cr-pointer {
  cursor: pointer;
}

button:focus {
  outline: 1px dotted !important;
}

.align-items-center {
  align-items: center;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: none !important;
}

input[type='checkbox'] {
  visibility: hidden;
}

input[type='checkbox'] + label:before {
  border: 1px solid #979797;
  border-radius: 3px;
  content: '\00a0';
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 0.25em 0 0;
  padding: 0;
  vertical-align: top;
  width: 16px;
}
input[type='checkbox']:checked + label:before {
  border: 1px solid #fbb12f;
  background: #fbb12f;
  color: #ffffff;
  content: '\2713';
  text-align: center;
}
input[type='checkbox']:checked + label:after {
  font-weight: bold;
}

input[type='checkbox']:focus + label::before {
  outline: rgb(59, 153, 252) auto 5px;
}

.label-checkbox {
  position: relative;
  padding: 0;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
  cursor: pointer;
}

.textarea-not-float,
.textarea-not-float:focus {
  border: none;
  border-bottom: 1px solid #979797;
  width: 50%;
  outline: none;
}

#new-user-button {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 5px 10px;
  color: #ffffff;
  cursor: pointer;
}

.status-active {
  color: #5f9d2e !important;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
}

.status-not-active {
  color: #d33232 !important;
  text-transform: uppercase;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
}

.warning-toaster {
  border-radius: 1.9px;
  background-color: #fffbe7;
  padding: 14px;
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49px;
  color: #f07a39;
}

.pagination li a {
  padding: 0 10px;
  color: #2f2f2f;
  cursor: pointer;
}

.pagination li.active a {
  color: #20a8d8;
}

.pagination .disabled a {
  color: grey;
}

.pagination .disabled a:hover {
  opacity: 1;
}

.pagination li a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.page-title {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3.27px;
  color: #231f20;
  font-family: 'TypoRound';
  text-transform: uppercase;
}

.background-image {
  max-height: 400px;
  height: 50vh;
  width: 100%;
  object-fit: cover;
  position: absolute;
}

.card-styles-1 {
  border-radius: 25px;
  background-color: #ffffff;
  padding: 20px 0;
  color: #6c6d71;
  margin-bottom: 150px;
}

.dividing__line {
  border: 2px solid #f7f7f7;
}

// .card {
//   border-radius: 25px;
//   box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
// }

.logo-image {
  z-index: 1;
  width: 50px;
  height: auto;
}

.logo-image-landing {
  position: absolute;
  z-index: 1;
  width: 100px;
  top: 120px;
  left: calc(50% - 50px);
}

.sign-in-landing {
  position: absolute;
  z-index: 2;
  right: 50px;
  top: 30px;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.33px;
  color: #fbb12f;
  cursor: pointer;
}

.logo-register {
  position: absolute;
  z-index: 1;
  width: 100px;
}

.form-position {
  margin-top: 30px;
}

.form-with-logo {
  margin-top: 100px;
}

.form-title {
  font-family: 'NotoSansBold';
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 10px;
  color: #231f20;
  padding: 20px 0;
}

.field-group {
  position: relative;

  input {
    padding: 20px 34px 6px;
    border: none;
    border-bottom: 1px solid #979797;
    outline: 0;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.34px;
    color: #2f2f2f;
    border-radius: 0;
  }

  label {
    display: inline-block;
    position: absolute;
    padding: 17px 34px;
    transition: all 150ms ease-in;
    color: #231f20;
    font-size: 12px;
  }
}

.input-icon {
  position: absolute;
  left: 0;
  top: 18px;
  width: 16px;
  height: 16px;
}

/* when the label is activated, it jumps up (using translateY) */
/* you could add animation here as well */
label.field-active {
  transform: translateY(-14px);
  font-size: 11px;
  color: #000;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff,
    0 -2px 0 #fff;
}
.floating-label {
  -webkit-appearance: none !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

input {
  padding: 20px 34px 6px;
  border: none;
  border-bottom: 1px solid #979797;
  outline: 0;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  color: #2f2f2f;
}

.input-not-float {
  padding: 0;
  width: 50%;
}

.input-not-float::placeholder {
  color: rgba(108, 109, 113, 0.6);
}

.input-not-float:disabled {
  opacity: 0.7;
  color: #5c6873;
}

.form-label {
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #6c6d71;
  flex: 0 0 22%;
}

.card-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
}

#button-link-change-password {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 10px 20px;
  color: #ffffff;
  text-transform: uppercase;
}

.custom-button {
  border-radius: 17.8px;
  background-color: #fbb12f;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  width: 270px;
}

.custom-button:disabled {
  opacity: 0.7;
}

.MuiCircularProgress-colorPrimary.white {
  color: #ffffff !important;
}

.MuiCardContent-root {
  padding: 1.25rem !important;
}

.MuiFormControlLabel-root {
  font-family: 'Manrope';
  text-transform: uppercase;
}

.MuiRadio-root,
.MuiCheckbox-root {
  color: #fbb12f !important;
}

.card-description-1 {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.28px;
  color: #6c6d71;
  font-family: 'NotoSansRegular';
  font-size: 14px;
}

.card-form {
  border-radius: 25px;
  box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
  background-color: #ffffff;
  z-index: 1;
}

.card-description {
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
}

.card-step {
  border-radius: 27.5px;
  border: solid 2px rgba(190, 190, 190, 0.5);
  padding: 20px 10px;
  min-height: 236px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #6c6d71;
}

.card-logo {
  margin-bottom: 60px;
}

.card-registration-button {
  text-align: center;
  padding: 12px 0;
  background-color: #fbb12f;
  color: #ffffff;
  border-radius: 15px;
  margin-top: 30px;
}

footer {
  text-align: center;
  padding: 30px 0;
  background-color: #231f20;
  color: #f7f7f7;
  width: 100%;
}

footer li {
  margin-right: 15px;
}

footer ul {
  display: inline-flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

li {
  list-style: none;
}

footer .copyright {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6c6d71;
  margin-right: 16px;
}

footer .logo-image-footer {
  width: 32px;
}

.line {
  border: 1px solid #231f20;
  margin-bottom: 30px;
}

.line-gray {
  border: 2px solid #f6f6f6;
}

.line-2 {
  border: solid 1.4px #231f20;
  margin-bottom: 10px;
  width: 100%;
}

.field-form {
  padding: 0 30px;
}

#forgot-password {
  font-family: 'NotoSansRegular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #231f20;
}

.btn-submit {
  border-radius: 25px;
  background-color: #fbb12f;
  width: 100%;
  font-family: 'NotoSansBold';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 3px;
  color: #ffffff;
  border: 1px solid transparent;
  padding: 8px 0;
  cursor: pointer;
}

.btn-submit:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.react-datepicker__input-container input {
  padding: 8px 4px;
  border: 1px solid #979797;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.mobile-datepicker {
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container input {
      width: 100%;
    }
  }
}

.button-search {
  border-radius: 8px !important;
  background: #ffde17 !important;
  outline: none !important;

  .button-text {
    font-size: 14px;
  }

  &:hover {
    background: #ffde17;
  }
}

.table th,
.table td {
  padding-left: 0;
  padding-right: 8px;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.table thead td {
  font-weight: 900;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: $c-secondary;
}

.table tbody td {
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.29px;
  color: $c-secondary;
  width: 100px;
  height: 48px;
}

td.status-pending,
div.status-pending {
  color: #f8c045 !important;
  font-weight: 600;
}

td.status-success,
div.status-success {
  color: #5f9d2e !important;
  font-weight: 600;
}

td.status-fail,
div.status-fail {
  color: #d33232 !important;
  font-weight: 600;
}

td.status-request,
div.status-request {
  color: #2971ff !important;
  font-weight: 600;
}

td.status-deleted,
div.status-deleted {
  color: #6c6d71 !important;
  font-weight: 600;
}

.show-limit-title {
  font-size: 10.1px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.show-limit {
  padding: 0 8px;
  border-right: 1px solid #979797;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.show-limit.active {
  color: #e46161;
}

.show-limit:first-child() {
  padding-left: 0;
}

.show-limit:last-child() {
  border-right: none;
}

.justify-content-space-between {
  justify-content: space-between;
}

.font-bold {
  font-weight: bold;
  color: #191a1f;
}

.table-display {
  color: #d0d0d0;
}

.paginate-button {
  background-image: url('../assets/img/pagination-button.svg');
  background-color: #f0f0f0;
  width: 30px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 10px;
  border: none;
}

.align-items-center {
  align-items: center;
}

.paginate-button:disabled {
  opacity: 0.5;
}

.paginate-button:first-child {
  transform: rotate(90deg);
}

.paginate-button:last-child {
  transform: rotate(-90deg);
}

.export-file {
  border-radius: 9.5px;
  background-color: #fbb12f;
  padding: 6px 14px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  border: none;
}

.search-button {
  border-radius: 8.4px;
  background-color: #ffde17;
  height: 30px;
  width: 30px;
  border: none;
}

.search__input {
  font-size: 12px !important;
  padding: 9px 8px !important;
  border: 1px solid #979797 !important;
  border-radius: 4px;
}

.search__select {
  font-size: 12px !important;
  padding: 7px !important;
  border: 1px solid #979797 !important;
  border-radius: 4px !important;
}

.form__label {
  font-family: 'NotoSansRegular';
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.39px;
  color: #6c6d71;
}

.clear__filter {
  cursor: pointer;
  width: max-content;
}

.MuiFormLabel-root.form__label {
  font-size: 1rem !important;
  font-family: 'NotoSansRegular' !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: -0.39px !important;
  color: #6c6d71 !important;
}

// Mui Table
.MuiTableCell-head {
  color: #231f20 !important;
  padding: 12px 8px 12px 0 !important;
  font-family: 'Manrope' !important;
  font-weight: 900 !important;
  border-top: 1px solid #c8ced3 !important;
}

.flex__filter {
  display: flex;
  align-items: flex-end;
  .react-datepicker__input-container input {
    padding: 0px 4px;
    border: none;
    border-bottom: 1px solid #979797;
    border-radius: 0;
    text-align: left;
  }

  .search-selects > .MuiSelect-select {
    font-family: 'NotoSansRegular';
    font-size: 12px;
    letter-spacing: 0.4px;
    padding: 4px 0 2px 0;
    font-weight: 600;
    color: #000;
    opacity: 0.8;
  }
}

@media only screen and (max-width: 1024px) {
  .container-mobile {
    padding: 0 27px;
  }

  .sort__options.show {
    position: relative;
    right: 0;
    width: 100%;
  }

  .card-logo {
    margin-bottom: 30px;
  }

  .logo-register {
    position: absolute;
    z-index: 1;
    width: 50px;
    left: calc(50% - 16px);
    top: 50px;
  }

  .image-container {
    position: absolute;
    width: 100%;
  }

  .form-register {
    border-radius: 25px;
    box-shadow: 0 2px 10px 0 rgba(190, 190, 190, 0.5);
    background-color: #ffffff;
    position: relative;
    top: 150px;
    margin-bottom: 30px;
  }

  .background-image-landing-page {
    height: 100vh;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  .step-landing {
    z-index: 1;
    padding: 0 20px;
  }

  .sign-up-now {
    font-size: 14px;
  }

  .card-step {
    height: 250px;
    margin-bottom: 20px;
  }

  footer .logo-image-footer {
    display: none;
  }

  .sign-in-landing {
    right: 20px;
  }
}

.search-title {
  color: #6c6d71 !important;
}

.search-group {
  max-width: 75%;
  .search-title {
    letter-spacing: 0.4px;
    color: #231f20;
    font-size: 16px;
  }

  .search-form {
    .MuiInputBase-input {
      font-family: 'NotoSansRegular';
      font-size: 12px;
      letter-spacing: 0.4px;
      padding: 4px 0 2px 0;
      font-weight: 600;
      color: #000;
      opacity: 0.8;
    }

    .MuiInputBase-input.Mui-disabled {
      opacity: 0.3;
    }

    // input::placeholder {
    //   color: rgb(78, 78, 78) !im;
    // }

    .MuiInput-underline {
      &::after {
        border-bottom-color: #231f20;
      }

      &.Mui-error::after {
        border-bottom-color: #f44336;
      }
    }

    .MuiInput-input::placeholder {
      letter-spacing: 0.4px;
      font-weight: 400;
    }
  }
}

@media only screen and (max-width: 768px) {
  .search-group {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1025px) {
  .background-image-landing-page {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .step-landing {
    z-index: 1;
    padding: 0 180px;
  }

  .card-step {
    margin-bottom: 20px;
  }

  .card-description {
    font-size: 10px;
  }
}

.react-datepicker-time__input {
  width: 100% !important;
}

.react-datepicker {
  position: absolute !important;
}

.profile-form {
  &__label {
    font-size: 17px;
    font-family: 'Manrope';
    font-weight: 600;
    color: $c-secondary;
  }

  &__input {
    .MuiInput-underline {
      &.Mui-disabled:before {
        border-bottom: none !important;
      }
    }

    .MuiInput-formControl {
      .MuiInput-input {
        font-size: 17px;
        font-family: 'Manrope' !important;
        font-weight: 600;
        color: $c-secondary !important;
      }
    }
  }
}

.trx-detail {
  &__title {
    font-size: 20px;
    font-weight: bold;
    font-family: 'Manrope';
  }

  &__content {
    font-family: 'Manrope';
    font-size: 16px;
    border-bottom: 40px solid transparent;

    &-title {
      font-weight: bold;
    }

    &:nth-last-child(1) {
      border-bottom: 0 solid;
    }
  }

  &__table {
    width: 100%;
    thead {
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }

    tbody tr {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }

    &-total {
      font-weight: bold;
    }

    &-charged {
      font-weight: bold;
      color: #d33232;
    }

    &-remaining {
      font-weight: bold;
      color: #5f9d2e;
    }
  }

  @media (max-width: 768px) {
    &__content {
      border-bottom: 10px solid transparent;
    }
  }
}
